import classnames from 'classnames'
import { useRef, useState } from 'react'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { useDispatchTable, useStateTable } from 'src/context/table'
import { setPage, setPerPage } from 'src/context/table/actions'
import css from './Footer.module.scss'

const quantities = [100, 40, 20, 10]

type FooterType = {
	totalCount: number
}

const Footer: React.FC<FooterType> = ({ totalCount }) => {
	const { state } = useStateTable()
	const { dispatch } = useDispatchTable()
	const [opened, setOpened] = useState(false)
	const onClick = () => {
		setOpened((prev) => !prev)
	}
	const onSelect = (value: number) => {
		dispatch(setPerPage(value))
		setOpened(false)
	}

	const getSelectedClass = (item: number) =>
		classnames(css.select__item, {
			[css.select__selected]: item === state.perPage
		})

	const ref = useRef()
	useOnClickOutside(ref, () => setOpened(false))

	const dropClass = classnames(css.select__list, {
		[css.open]: opened
	})
	const isLastPage = Math.ceil(totalCount / state.perPage) === state.page

	const onNextPage = () => {
		dispatch(setPage(state.page + 1))
	}

	const onPrevPage = () => {
		dispatch(setPage(state.page - 1))
	}
	return (
		<div className={css.footer}>
			<div className={css.flex}>
				<p className={css.per_page}>Rows per page</p>
				<div className={css.select} ref={ref}>
					<button className={css.select__button} onClick={onClick}>
						{state.perPage} <i className="icon-arrow-down-bold" />
					</button>
					<ul className={dropClass}>
						{quantities.map((item) => (
							<li key={item}>
								<button
									className={getSelectedClass(item)}
									onClick={() => onSelect(item)}
								>
									{item}
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className={css.flex}>
				<p className={css.text}>
					<span>
						{state.page * state.perPage - (state.perPage - 1)} -{' '}
						{state.page * state.perPage}
					</span>{' '}
					of <span>{totalCount}</span> entries
				</p>
				<div className={css.pages}>
					<button
						className={`${css.page_left} icon-arrow-down-bold`}
						aria-label="Previous page"
						onClick={onPrevPage}
						disabled={state.page === 1}
					/>
					<button
						className={`${css.page_right} icon-arrow-down-bold`}
						aria-label="Next page"
						onClick={onNextPage}
						disabled={isLastPage}
					/>
				</div>
			</div>
		</div>
	)
}

export default Footer
