import { useEffect, useMemo, useState } from 'react'
import Header from 'src/components/modules/Header'
import { Progress } from 'src/components/modules/Header/modules'
import WithCredentials from 'src/hoc/withCredentials'
import { useDispatchSteps } from 'src/context/steps-context'
import { useUser } from 'src/context/auth'
import { useNavigate } from 'react-router'
import routesNames from 'src/router/routesNames'
import { LicenceType } from 'src/components/modules/AdminAddLicence/AdminAddLicence'
import { useLocation } from 'react-router-dom'
import AdminAddLicence from 'src/components/modules/AdminAddLicence'
import AdminAddChild from 'src/components/modules/AdminAddChild/AdminAddChild'

const DashboardAddChildManually: React.FC = () => {
	const navigate = useNavigate()
	const { state: locationState } = useLocation() as { state: { parentId: string } }
	const { parentId } = locationState
	const { dispatch } = useDispatchSteps()
	const { state } = useUser()

	const [step, setStep] = useState(1)
	const [licenceCodes, setLicenceCodes] = useState<LicenceType[]>([])

	const nextStep = () => setStep((prevState) => (prevState + 1 <= 3 ? prevState + 1 : prevState))
	const prevStep = () => setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState))

	const stepsList = useMemo(
		() => ({
			1: (
				<AdminAddLicence
					parentId={parentId}
					prevStep={prevStep}
					nextStep={nextStep}
					setLicenceCodes={setLicenceCodes}
					backButtonType="cancel"
				/>
			),
			2: <AdminAddChild licences={licenceCodes} parentId={parentId} prevStep={prevStep} />
		}),
		[step, parentId, licenceCodes]
	)

	useEffect(() => {
		if (!state.user.isAdmin) {
			navigate(routesNames.DASHBOARD)
			return
		}

		dispatch({
			type: 'SET_STEPS',
			payload: { steps: 2 }
		})
	}, [])

	return (
		<>
			<Header type="profile" />
			<div className="dashboard-step dashboard-step__add-parent-step">
				<div className="container container-md-center container-lg-center">
					<Progress step={step} className="dashboard-step__progress" position="top" />
					{stepsList[step]}
				</div>
			</div>
		</>
	)
}

export default WithCredentials(DashboardAddChildManually)
