import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import Header from 'src/components/modules/Header'
import { useDispatchUser } from 'src/context/auth'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { CONFIRM_USER_EMAIL } from 'src/graphql/mutation'
import ROUTES_NAME from 'src/router/routesNames'

const VerifyEmail = () => {
	const { token, uuid } = useParams()
	const { showModal } = useGlobalBadgeContext()
	const { dispatch } = useDispatchUser()
	const navigate = useNavigate()
	const [error, setError] = useState(null)
	const [confirmUserEmail] = useMutation(CONFIRM_USER_EMAIL, {
		onCompleted: (data) => {
			if (data.confirmUserEmail.ok) {
				setError(null)
				if (data.confirmUserEmail.user.isAuthenticated) {
					dispatch({
						type: 'LOGIN',
						payload: {
							user: data.confirmUserEmail.user
						}
					})
					navigate(ROUTES_NAME.SETTINGS)
					showModal('success', {
						color: 'success',
						text: 'You have successfully changed your email address'
					})
				} else {
					navigate(ROUTES_NAME.LOGIN)
				}
			} else {
				setError(data.confirmUserEmail.errors[0].errors[0])
			}
		}
	})
	useEffect(() => {
		if (uuid && token) {
			confirmUserEmail({ variables: { uidb64: uuid, token } })
		}
	}, [])

	return (
		<div>
			<Header />
			{error ? <div>{error}</div> : <div>Loading...</div>}
		</div>
	)
}

export default VerifyEmail
