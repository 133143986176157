import Header from 'src/components/modules/Header'
import { FormProvider, useForm } from 'react-hook-form'
import Button from 'src/components/elements/Button'
import FieldInput from 'src/components/elements/FieldInput'
import Checkbox from 'src/components/elements/Checkbox'
import { useNavigate } from 'react-router-dom'
import CustomSelect from 'src/components/elements/CustomSelect'
import { isEmptyObject, serializeErrors, transformCountries } from 'src/utils'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_USER } from 'src/graphql/mutation'
import useLocalStorage from 'src/hooks/useLocalStorage'
import { useDispatchUser } from 'src/context/auth'
import ConnectForm from 'src/components/elements/ConnectForm'
import useMultiErrors from 'src/hooks/useMultiErrors'
import ROUTES_NAME from 'src/router/routesNames'
import { useEffect, useMemo } from 'react'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { useSteps } from 'src/context/steps-context'
import { GET_ANONYMOUS_USER, GET_COUNTRIES } from 'src/graphql/query'
import AuthenticatedToDashboard from 'src/hoc/AuthenticatedToDashboard'
import { termsAndPolicyLinks } from 'src/utils/links'
import { orderingFormData, validationSchema } from './form'

const Step1 = () => {
	const { data: getCountries, loading: loadingCountries } = useQuery(GET_COUNTRIES)
	const countries = transformCountries(getCountries?.countries)

	const defaultValues = useMemo(() => {
		return {
			phoneNumber: '+44',
			firstName: '',
			lastName: '',
			email: '',
			country: countries ? countries[0] : { label: '', value: '' },
			city: '',
			address: '',
			postCode: '',
			password1: '',
			password2: '',
			termsOfUse: false
		}
	}, [countries])

	const methods = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(validationSchema),
		defaultValues,
		shouldFocusError: false
	})

	const { data: user } = useQuery(GET_ANONYMOUS_USER, {
		fetchPolicy: 'network-only'
	})
	const navigate = useNavigate()

	useEffect(() => {
		if (user?.anonymousUser) {
			if (!user.anonymousUser.isPaid) {
				navigate(ROUTES_NAME.PAYMENT_STEP)
			}
		}
	}, [user])
	const { state } = useSteps()
	const step = state.steps ? 2 : 1

	const { dispatch } = useDispatchUser()
	const { showModal, renderComponent } = useGlobalBadgeContext()
	const { storedValue: licensesValue, setValue } = useLocalStorage('licenses', [])
	const {
		handleSubmit,
		formState: { errors }
	} = methods
	const { errorsPack, setErrors } = useMultiErrors(errors)

	const [createUser, { loading }] = useMutation(CREATE_USER, {
		onCompleted: (data) => {
			if (data.createUser.ok) {
				dispatch({
					type: 'LOGIN',
					payload: {
						user: data.createUser.user
					}
				})
				setValue([])
				navigate(ROUTES_NAME.STEP2)
			} else {
				setErrors(serializeErrors(data.createUser.errors))
			}
		}
	})

	useEffect(() => {
		if (errorsPack.codes) {
			showModal('error', {
				color: 'error',
				text: 'Licence is not valid.',
				time: 15000
			})
		}
	}, [errorsPack])

	const onSubmit = handleSubmit((val) => {
		const codes = user.anonymousUser?.licences?.codes || licensesValue
		const variables = {
			...val,
			countryId: val.country.value,
			codes
		}
		createUser({
			variables
		})
	})
	const onCancel = () => {
		navigate(ROUTES_NAME.HOME)
	}
	const enabled = isEmptyObject(errors)
	return (
		<div className="step">
			<Header type="progress" step={step} />
			<FormProvider {...methods}>
				<form onSubmit={onSubmit} className="step__form">
					<div className="container container-md-center container-lg-center">
						{renderComponent()}
						<div className="step__titles">
							<h2 onSubmit={onSubmit} className="step__title">
								Create your parent profile to login and manage your child(ren)’s
								account
							</h2>
						</div>
						<div className="step__desktopGrid">
							{orderingFormData.map((item) => {
								if (item.id === 'country') {
									return (
										<ConnectForm name={item.id} key={item.id}>
											<CustomSelect
												isLoading={loadingCountries}
												list={countries}
												{...item}
												error={errorsPack}
												withSearch
												className="step-field-wrapper"
											/>
										</ConnectForm>
									)
								}
								return (
									<FieldInput
										key={item.id}
										{...item}
										connectForm
										errors={errorsPack[item.name]}
										required
										className="step-field-wrapper"
									/>
								)
							})}
							<div>
								<div className="step__checkbox">
									<Checkbox
										id="termsOfUse"
										label=""
										name="termsOfUse"
										error={errorsPack}
										register={methods.register}
									/>
									<span className="step__checkboxText">
										I agree with
										<a href={termsAndPolicyLinks.terms.link}>Terms of use</a>
										and
										<a href={termsAndPolicyLinks.policy.link}>Privacy</a>
									</span>
								</div>
								{errorsPack?.termsOfUse && (
									<span className="formControl__error">
										{errors?.termsOfUse?.message}
									</span>
								)}
							</div>
						</div>
					</div>

					<div className="step-buttons">
						<div className="step-buttons__wrapper">
							<Button color="empty" onClick={onCancel}>
								Cancel
							</Button>
							<Button
								type="submit"
								color="primary"
								isDisable={!enabled}
								isLoading={loading}
								classname="step-buttons__next"
							>
								Continue
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default AuthenticatedToDashboard(Step1)
