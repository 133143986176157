import { useMemo } from 'react'
import Loader from 'src/components/elements/Loader'
import Header from 'src/components/modules/Header'
import { useUser } from 'src/context/auth'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import WithCredentials from 'src/hoc/withCredentials'
import Form from './modules/Form'
import styles from './Settings.module.scss'

const Settings = () => {
	const { showModal, renderComponent } = useGlobalBadgeContext()
	const { state } = useUser()
	const loading = useMemo(() => {
		return !state.user.isAuthenticated
	}, [state.user])
	const toggleBadge = () => {
		showModal('success', {
			color: 'success',
			text: 'You have successfully changed your personal info'
		})
	}
	return (
		<>
			<Header type="profile" />
			<div className={styles.setting}>
				<div className="container">
					<div className={styles.setting__wrapper}>
						{renderComponent()}
						<h2 className={styles.setting__title}>Settings</h2>
						<p className={styles.setting__subtitle}>
							Make changes to your personal information, email and password
						</p>
						{loading ? (
							<Loader width="80px" center color="grey" />
						) : (
							<Form user={state.user} toggleBadge={toggleBadge} />
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default WithCredentials(Settings)
