import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { TableContextProvider } from 'src/context/table'
import AdminTable from '../../AdminTable'

const AdminsTabs = () => {
	return (
		<Tabs className="dashboard-tabs" selectedTabClassName="dashboard-tabs__tabActive">
			<TabList className="dashboard-tabs__list">
				<Tab className="dashboard-tabs__tab">Parents</Tab>
				<Tab className="dashboard-tabs__tab">Reporting</Tab>
			</TabList>
			<TabPanel className="dashboard-tabs__panel">
				<TableContextProvider>
					<AdminTable />
				</TableContextProvider>
			</TabPanel>
			<TabPanel className="dashboard-tabs__panel" />
		</Tabs>
	)
}

export default AdminsTabs
