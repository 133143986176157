import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PageLoading from 'src/components/modules/PageLoading'
import ROUTES_NAME from 'src/router/routesNames'
import { useUser } from '../context/auth'

const AuthenticatedToDashboard = (Component) =>
	// eslint-disable-next-line react/function-component-definition
	function Comp(props) {
		const navigate = useNavigate()
		const { state } = useUser()
		useEffect(() => {
			if (!state.loading) {
				if (state?.user?.isAuthenticated) {
					navigate(ROUTES_NAME.DASHBOARD)
				}
			}
		}, [state.user?.isAuthenticated, state.loading])

		if (state.loading) {
			return <PageLoading />
		}
		return <Component {...props} />
	}

export default AuthenticatedToDashboard
