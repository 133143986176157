import { useEffect, useMemo, useState } from 'react'
import Header from 'src/components/modules/Header'
import { Progress } from 'src/components/modules/Header/modules'
import WithCredentials from 'src/hoc/withCredentials'
import { useDispatchSteps } from 'src/context/steps-context'
import { useUser } from 'src/context/auth'
import { useNavigate } from 'react-router'
import routesNames from 'src/router/routesNames'
import AdminAddLicence, {
	LicenceType
} from 'src/components/modules/AdminAddLicence/AdminAddLicence'
import Step1 from 'src/pages/DashboardAddParentManually/modules/Step1'
import AdminAddChild from 'src/components/modules/AdminAddChild/AdminAddChild'

const DashboardAddParentManually: React.FC = () => {
	const navigate = useNavigate()
	const { dispatch } = useDispatchSteps()
	const { state } = useUser()

	const [step, setStep] = useState(1)
	const [parentId, setParentId] = useState('')
	const [licenceCodes, setLicenceCodes] = useState<LicenceType[]>([])

	const nextStep = () => setStep((prevState) => (prevState + 1 <= 3 ? prevState + 1 : prevState))
	const prevStep = () => setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState))

	const stepsList = useMemo(
		() => ({
			1: <Step1 nextStep={nextStep} setParentId={setParentId} />,
			2: (
				<AdminAddLicence
					parentId={parentId}
					prevStep={prevStep}
					nextStep={nextStep}
					setLicenceCodes={setLicenceCodes}
					backButtonType="back"
				/>
			),
			3: <AdminAddChild licences={licenceCodes} parentId={parentId} prevStep={prevStep} />
		}),
		[step, parentId, licenceCodes]
	)

	useEffect(() => {
		if (!state.user.isAdmin) {
			navigate(routesNames.DASHBOARD)
			return
		}

		dispatch({
			type: 'SET_STEPS',
			payload: { steps: 3 }
		})
	}, [])

	return (
		<>
			<Header type="profile" />
			<div className="dashboard-step dashboard-step__add-parent-step">
				<div className="container container-md-center container-lg-center">
					<Progress step={step} className="dashboard-step__progress" position="top" />
					{stepsList[step]}
				</div>
			</div>
		</>
	)
}

export default WithCredentials(DashboardAddParentManually)
