import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import {
	validationSchema,
	orderingFormData
} from 'src/pages/DashboardAddParentManually/modules/Step1/form'
import FieldInput from 'src/components/elements/FieldInput'
import Button from 'src/components/elements/Button'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { isEmptyObject, serializeErrors } from 'src/utils'
import useMultiErrors from 'src/hooks/useMultiErrors'
import { useNavigate } from 'react-router'
import routesNames from 'src/router/routesNames'
import { useMutation } from '@apollo/client'
import { CREATE_PARENT_MANUALLY } from 'src/graphql/mutation'
import styles from './Step1.module.scss'

interface IStep1 {
	setParentId: Dispatch<SetStateAction<string>>
	nextStep: () => void
}

const Step1: React.FC<IStep1> = ({ nextStep, setParentId }) => {
	const navigate = useNavigate()
	const { renderComponent, showModal } = useGlobalBadgeContext()

	const defaultValues = useMemo(() => {
		return {
			firstName: '',
			lastName: '',
			email: ''
		}
	}, [])

	const methods = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(validationSchema),
		defaultValues,
		shouldFocusError: false
	})
	const {
		handleSubmit,
		formState: { errors }
	} = methods
	const enabled = isEmptyObject(errors)
	const { errorsPack, setErrors } = useMultiErrors(errors)

	const [addParentManually, { loading }] = useMutation(CREATE_PARENT_MANUALLY, {
		onCompleted: (data) => {
			if (!data.createParentManually.ok) {
				setErrors(serializeErrors(data.createParentManually.errors))
				return
			}

			setParentId(data.createParentManually.user.id)
			nextStep()
		}
	})

	const onSubmit = handleSubmit((variables) => {
		addParentManually({
			variables
		})
	})
	const onCancel = () => navigate(routesNames.DASHBOARD)

	useEffect(() => {
		if (errorsPack.codes) {
			showModal('error', {
				color: 'error',
				text: 'Something went wrong.',
				time: 15000
			})
		}
	}, [errorsPack])

	return (
		<div className="dashboard-step__titles">
			<h3 className="dashboard-step__title">Add parent information</h3>
			<p className="dashboard-step__subtitle">
				Please type in the required information about the parent to add them to the
				dashboard
			</p>
			<FormProvider {...methods}>
				<form onSubmit={onSubmit} className={styles.step__form}>
					<div>
						{renderComponent()}
						<div>
							{orderingFormData.map((item) => (
								<FieldInput
									key={item.id}
									{...item}
									errors={errorsPack[item.name]}
									connectForm
									required
									className="step-field-wrapper"
								/>
							))}
						</div>
					</div>

					<div className="step-buttons">
						<div className="step-buttons__wrapper">
							<Button color="empty" onClick={onCancel}>
								Cancel
							</Button>
							<Button
								type="submit"
								color="primary"
								isDisable={!enabled}
								isLoading={loading}
								classname="step-buttons__next"
							>
								Continue
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default Step1
