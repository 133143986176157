import {
	HomePage,
	Step1,
	Step2,
	Step3,
	Dashboard,
	Login,
	Settings,
	VerifyEmail,
	NoFound,
	RecoverPassword,
	PasswordReset,
	PaymentStep,
	DashboardAddLicence,
	DashboardPayment,
	DashboardCreateChild,
	DashboardAddParentManually,
	GetConnected,
	DashboardAddChildManually
} from '../pages'
import ROUTES_NAME from './routesNames'

export const ROUTES = [
	{
		path: ROUTES_NAME.HOME,
		Element: HomePage
	},
	{
		path: ROUTES_NAME.LOGIN,
		Element: Login
	},
	{
		path: ROUTES_NAME.DASHBOARD,
		Element: Dashboard
	},
	{
		path: ROUTES_NAME.SETTINGS,
		Element: Settings
	},
	{
		path: ROUTES_NAME.STEP1,
		Element: Step1
	},
	{
		path: ROUTES_NAME.PAYMENT_STEP,
		Element: PaymentStep
	},
	{
		path: ROUTES_NAME.STEP2,
		Element: Step2
	},
	{
		path: ROUTES_NAME.STEP3,
		Element: Step3
	},
	{
		path: ROUTES_NAME.RECOVER_PASSWORD,
		Element: RecoverPassword
	},
	{
		path: '/email-verify/:uuid/:token',
		Element: VerifyEmail
	},
	{
		path: '/password-reset/:uuid/:token',
		Element: PasswordReset
	},
	{
		path: ROUTES_NAME.DASHBOARD_ADD_LICENCE,
		Element: DashboardAddLicence
	},
	{
		path: ROUTES_NAME.DASHBOARD_PAYMENT,
		Element: DashboardPayment
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_CHILD,
		Element: DashboardCreateChild
	},
	{
		path: ROUTES_NAME.DASHBOARD_CREATE_PARENT,
		Element: DashboardAddParentManually
	},
	{
		path: ROUTES_NAME.DASHBOARD_GET_CONNECTED,
		Element: GetConnected
	},
	{
		path: ROUTES_NAME.DASHBOARD_ADD_CHILD,
		Element: DashboardAddChildManually
	},

	{
		path: '*',
		Element: NoFound
	}
]
export default ROUTES
