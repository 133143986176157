import { createContext, FC, useContext, useMemo, useReducer } from 'react'
import { Action, ActionEnum, Dispatch, State, UserProviderProps } from './types'

const TableStateContext = createContext<{ state: State } | undefined>(undefined)
const TableDispatchContext = createContext<{ dispatch: Dispatch } | undefined>(undefined)

const initialState: State = {
	page: 1,
	perPage: 10,
	orderBy: 'NAME',
	sortBy: 'ASC',
	status: 'ALL',
	search: ''
}
const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case ActionEnum.SET_PAGE: {
			return {
				...state,
				page: action.payload
			}
		}
		case ActionEnum.SET_PER_PAGE: {
			return {
				...state,
				perPage: action.payload
			}
		}
		case ActionEnum.SET_SEARCH: {
			return {
				...state,
				search: action.payload
			}
		}
		case ActionEnum.SET_STATUS: {
			return {
				...state,
				status: action.payload
			}
		}
		case ActionEnum.SET_SORT_BY: {
			return {
				...state,
				...action.payload
			}
		}
		default:
			throw new Error(`Unknown action: ${action}`)
	}
}

export const TableContextProvider: FC = ({ children }: UserProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const dispatchFn = useMemo(() => ({ dispatch }), [dispatch])
	const getState = useMemo(() => ({ state }), [state])

	return (
		<TableDispatchContext.Provider value={dispatchFn}>
			<TableStateContext.Provider value={getState}>{children}</TableStateContext.Provider>
		</TableDispatchContext.Provider>
	)
}

export const useStateTable = () => useContext<{ state: State }>(TableStateContext)
export const useDispatchTable = () => useContext<{ dispatch: Dispatch }>(TableDispatchContext)
