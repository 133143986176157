import Loader from 'src/components/elements/Loader'
import {
	AddChild,
	AddChildAccess,
	AddChildFooter,
	AddChildHeader
} from 'src/components/modules/AddChild'
import Button from 'src/components/elements/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import routesNames from 'src/router/routesNames'
import { useEffect } from 'react'
import { useUser } from 'src/context/auth'
import WithCredentials from 'src/hoc/withCredentials'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import Header from 'src/components/modules/Header'
import { useQuery } from '@apollo/client'
import { GET_PARENT } from 'src/graphql/query'

const GetConnected: React.FC = () => {
	const navigate = useNavigate()
	const { state: locationState } = useLocation() as { state: { parentId: string } }
	const { parentId } = locationState
	const { renderComponent, showModal } = useGlobalBadgeContext()
	const { state } = useUser()

	const { data, loading } = useQuery(GET_PARENT, { variables: { id: parentId } })

	const onGoToDashboardClick = () => {
		navigate(routesNames.DASHBOARD)
	}

	useEffect(() => {
		if (!state.user.isAdmin) {
			navigate(routesNames.DASHBOARD)
			return
		}

		if (!locationState?.parentId) {
			showModal('error', {
				color: 'error',
				text: 'Something went wrong.',
				time: 15000
			})
		}
	}, [])

	return (
		<>
			<Header type="profile" />
			<div className="step__form">
				<div className="container container-md-center container-lg-center">
					{renderComponent()}
					<div className="step__titles">
						<h2 className="step__title">Let’s get connected</h2>
						<p className="step__subtitle">
							Connect the application to your child's device to get protection
						</p>
					</div>
					<div className="step__desktopGrid">
						{loading && !data && <Loader width="80px" color="grey" center />}
						{data?.parent?.children?.map((child) => (
							<AddChild key={child.licence.code}>
								<AddChildHeader
									childName={child.name}
									status={child.licence?.status}
									loading={loading}
									type={child.licence?.licenceType}
								/>
								<AddChildAccess
									childName={child.name}
									pin={child.licence.profileCode}
								/>
								<AddChildFooter status={child.licence.status} />
							</AddChild>
						))}
					</div>
				</div>

				<div className="step-buttons">
					<div className="step-buttons__wrapperRight">
						<Button type="submit" color="primary" onClick={onGoToDashboardClick}>
							Go to dashboard
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}

export default WithCredentials(GetConnected)
