import { gql } from '@apollo/client'

export const CHECK_VALID_LICENSE = gql`
	query licenceIsValid($codes: [String]) {
		licenceIsValid(codes: $codes) {
			isValid
			code
			error
		}
	}
`

export const CHECK_LICENSE = gql`
	query licence($code: String) {
		licence(code: $code) {
			valid
		}
	}
`

export const GET_COUNTRY = gql`
	query country($id: ID) {
		country(id: $id) {
			id
			name
			icon
		}
	}
`
export const GET_COUNTRIES = gql`
	query countries {
		countries {
			id
			name
			icon
		}
	}
`
export const GET_POLICIES = gql`
	query policies {
		policies {
			name
			id
		}
	}
`

export const IS_AUTHENTICATED = gql`
	query user {
		user {
			id
			postCode
			country {
				id
				name
				icon
			}
			city
			phoneNumber
			firstName
			lastName
			email
			address
			licences(inProgress: true) {
				code
				status
				school {
					name
				}
				licenceType
			}
			isAuthenticated
			isAdmin
			extraLicences {
				totalAmount
				licences {
					licenceType
					quantity
					price
				}
			}
		}
	}
`

export const GET_CHILDREN = gql`
	query children {
		children {
			id
			name
			licence {
				code
				profileCode
				status
				school {
					name
				}
				expirationDaysLeft
				expirationDate
				paymentStatus
				licenceType
			}
			recreationTime {
				start
				end
			}
			policy {
				name
				id
			}
			canResetRecreationTime
		}
	}
`
export const GET_TIME_INTERVAL_LIST = gql`
	query timeIntervalList($id: ID) {
		child(id: $id) {
			id
			timeIntervalList
		}
	}
`

export const GET_CHILD = gql`
	query child($id: ID) {
		child(id: $id) {
			id
			licence {
				profileCode
			}
			name
			devices(withDate: true) {
				id
				name
				model
				connectedDate
			}
		}
	}
`

export const GET_LICENSE_PRICE = gql`
	query licencePrice {
		licencePrice
	}
`
export const GET_ANONYMOUS_USER = gql`
	query anonymousUser {
		anonymousUser {
			licences {
				totalAmount
				licences {
					licenceType
					quantity
					price
				}
				codes
			}
			isPaid
		}
	}
`

export const GET_EXTRA_LICENCES = gql`
	query getExtraLicences {
		user {
			id
			extraLicences {
				totalAmount
				licences {
					licenceType
					quantity
					price
				}
			}
		}
	}
`
export const GET_INPROGRESS_LICENCES = gql`
	query getInProgressLicences {
		user {
			id
			licences(inProgress: true) {
				code
				status
				school {
					name
				}
				licenceType
			}
		}
	}
`

export const GET_TIME_INTERVALS = gql`
	query timeIntervals {
		timeIntervals {
			label
			value
		}
	}
`

export const GET_ANALYTICS = gql`
	query analytics($childId: ID, $timeInterval: String = "-30d") {
		analytics(childId: $childId, timeInterval: $timeInterval) {
			queries {
				name
				queries
			}
			blockedReasons {
				name
				queries
			}
			querySeries {
				queries {
					name
					queries
				}
				times
			}
		}
	}
`

export const GET_PARENTS = gql`
	query parents(
		$page: Int
		$perPage: Int
		$orderBy: OrderBy
		$sortBy: SortOrder
		$status: Status
		$search: String
	) {
		parents(
			page: $page
			perPage: $perPage
			orderBy: $orderBy
			sortBy: $sortBy
			status: $status
			search: $search
		) {
			parents {
				id
				firstName
				lastName
				email
				status
				children {
					id
					name
				}
			}
			quantity
		}
	}
`

export const GET_PARENTS_QUANTITY = gql`
	query parents_quantity {
		parents {
			quantity
		}
	}
`
export const GET_PARENT = gql`
	query parent($id: ID) {
		parent(id: $id) {
			id
			firstName
			lastName
			email
			children {
				id
				name
				licence {
					code
					status
					licenceType
					profileCode
				}
			}
		}
	}
`
