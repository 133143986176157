import { useEffect } from 'react'
import Table from 'src/components/elements/Table'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_PARENTS, GET_PARENTS_QUANTITY } from 'src/graphql/query'
import { useStateTable } from 'src/context/table'
import { DashboardType } from 'src/graphql/types'
import css from './AdminTable.module.scss'
import AdminTableHeader from '../AdminTableHeader'
import EmptyTableScreen from '../DashboardTabs/EmtyTableScreen/EmptyTableScreen'

const AdminTable = () => {
	const { state } = useStateTable()

	const [getParents, { data }] = useLazyQuery<{ parents: DashboardType }>(GET_PARENTS)
	const { data: quantity } = useQuery(GET_PARENTS_QUANTITY)

	useEffect(() => {
		getParents({
			variables: {
				...state,
				status: state.status !== 'ALL' ? state.status : null
			}
		})
	}, [state])

	if (!quantity?.parents?.quantity) {
		return <EmptyTableScreen />
	}

	return (
		<div className={css.wrapper}>
			<AdminTableHeader />
			<Table parents={data?.parents} />
		</div>
	)
}

export default AdminTable
