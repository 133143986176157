import { gql } from '@apollo/client/core'

const FRAGMENT_USER = gql`
	fragment User on UserType {
		id
		postCode
		country {
			id
			name
			icon
		}
		city
		phoneNumber
		firstName
		lastName
		email
		licences(inProgress: true) {
			activationDate
			status
			code
			school {
				name
			}
			isValid
			profileCode
		}
		isAuthenticated
		isAdmin
	}
`
export default FRAGMENT_USER
