import {
	ActionEnum,
	PageAction,
	PerPageAction,
	SearchAction,
	SortByEmailAction,
	SortByNameAction,
	StatusAction,
	StatusPayload
} from './types'

export const setStatus = (type: StatusPayload): StatusAction => ({
	type: ActionEnum.SET_STATUS,
	payload: type
})

export const setPage = (page: number): PageAction => ({
	type: ActionEnum.SET_PAGE,
	payload: page
})

export const setPerPage = (perPage: number): PerPageAction => ({
	type: ActionEnum.SET_PER_PAGE,
	payload: perPage
})

export const setSearch = (search: string): SearchAction => ({
	type: ActionEnum.SET_SEARCH,
	payload: search
})

export const setSortByNameDesc = (): SortByNameAction => ({
	type: ActionEnum.SET_SORT_BY,
	payload: {
		sortBy: 'DESC',
		orderBy: 'NAME'
	}
})

export const setSortByNameAsc = (): SortByNameAction => ({
	type: ActionEnum.SET_SORT_BY,
	payload: {
		sortBy: 'ASC',
		orderBy: 'NAME'
	}
})

export const setSortByEmailDesc = (): SortByEmailAction => ({
	type: ActionEnum.SET_SORT_BY,
	payload: {
		sortBy: 'DESC',
		orderBy: 'EMAIL'
	}
})

export const setSortByEmailAsc = (): SortByEmailAction => ({
	type: ActionEnum.SET_SORT_BY,
	payload: {
		sortBy: 'ASC',
		orderBy: 'EMAIL'
	}
})
