import { useQuery } from '@apollo/client'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { GET_CHILDREN } from 'src/graphql/query'
import { TChild } from 'src/graphql/types'
import DashboardLicences from '../modules/DashboardLicences'
import DashboardReporting from '../modules/DashboardReporting'

const ParentsTabs = () => {
	const { data, loading, refetch } = useQuery(GET_CHILDREN)

	const childrenNames = data?.children.map((child: TChild['child']) => ({
		id: child.id,
		value: child.id,
		label: child.name
	}))

	return (
		<Tabs className="dashboard-tabs" selectedTabClassName="dashboard-tabs__tabActive">
			<TabList className="dashboard-tabs__list">
				<Tab className="dashboard-tabs__tab">Licences</Tab>
				<Tab className="dashboard-tabs__tab">Reporting</Tab>
			</TabList>
			<TabPanel className="dashboard-tabs__panel">
				<DashboardLicences
					childrenArr={data?.children || []}
					loading={loading}
					refetch={refetch}
				/>
			</TabPanel>
			<TabPanel className="dashboard-tabs__panel">
				<DashboardReporting childrenNames={childrenNames} loadingChildren={loading} />
			</TabPanel>
		</Tabs>
	)
}

export default ParentsTabs
