import { useMemo } from 'react'
import { FormProvider, useFormContext, useForm } from 'react-hook-form'
import Button from 'src/components/elements/Button'
import { AddChild, AddChildHeader, AddChildForm } from 'src/components/modules/AddChild'
import { capitalizeString, serializeErrorsOnSteps, transformPolicies } from 'src/utils'
import { GET_INPROGRESS_LICENCES, GET_POLICIES } from 'src/graphql/query'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_CHILD } from 'src/graphql/mutation'
import { useNavigate } from 'react-router-dom'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import ROUTES_NAME from 'src/router/routesNames'
import { LicenceType } from 'src/components/modules/AdminAddLicence/AdminAddLicence'

export const FormButton: React.FC<{
	licences: LicenceType[]
	loading: boolean
	isBackButton?: boolean
	onBackClick?: () => void
}> = ({ licences, loading, isBackButton = false, onBackClick }) => {
	const { watch } = useFormContext()

	const allFields = watch()

	const disable = useMemo(() => {
		const children = licences?.map((_, index) => {
			if (
				allFields[`policy${index}`]?.value === 'default' ||
				!allFields[`childName${index}`]
			) {
				return true
			}
			return false
		})
		return children?.includes(true)
	}, [allFields, licences])

	return (
		<div className="step-buttons">
			<div className={isBackButton ? 'step-buttons__wrapper' : 'step-buttons__wrapperRight'}>
				{isBackButton && (
					<Button color="empty" classname="step-buttons__backBtn" onClick={onBackClick}>
						<i className="icon-arrow-left" />
						Back
					</Button>
				)}
				<Button
					type="submit"
					color="primary"
					isDisable={disable}
					isLoading={loading}
					classname="step-buttons__next"
				>
					Continue
				</Button>
			</div>
		</div>
	)
}

const Form = () => {
	const methods = useForm({
		mode: 'onSubmit'
	})
	const navigate = useNavigate()
	const { showModal, renderComponent } = useGlobalBadgeContext()

	const { data: licences } = useQuery(GET_INPROGRESS_LICENCES, {
		fetchPolicy: 'network-only'
	})
	const { handleSubmit, control } = methods
	const { data: policies, loading } = useQuery(GET_POLICIES)
	const [createChild, { loading: loadingBtn }] = useMutation(CREATE_CHILD, {
		onCompleted: (data) => {
			if (data.createChild.ok) {
				navigate(ROUTES_NAME.STEP3)
			}
			if (data.createChild.errors) {
				window.scrollTo(0, 0)
				const error = serializeErrorsOnSteps(data.createChild.errors)
				showModal('error', {
					color: 'error',
					text: error,
					time: 30000
				})
			}
		}
	})

	const list = transformPolicies(policies?.policies)
	const onSubmit = handleSubmit((data) => {
		const children = licences?.user?.licences.map((item, index) => {
			return {
				name: capitalizeString(data[`childName${index}`]),
				policyId: data[`policy${index}`].value,
				licenceCode: item.code
			}
		})
		createChild({ variables: { children } })
	})

	return (
		<FormProvider {...methods}>
			<form onSubmit={onSubmit} className="step__form">
				<div className="container container-md-center container-lg-center">
					{renderComponent()}
					<div className="step__titles step__md_mt38">
						{licences?.user?.licences.length ? (
							<>
								<h2 className="step__title">Create your child’s profile</h2>
								<p className="step__subtitle">
									Add a <strong>name</strong> and select the appropriate{' '}
									<strong>policy</strong>
								</p>
							</>
						) : (
							<h2 className="step__title">You do not have unactivated licences</h2>
						)}
					</div>
					<div className="step__desktopGrid">
						{licences?.user?.licences?.map((license, index) => (
							<AddChild key={license.code}>
								<AddChildHeader
									index={index}
									status={license.status}
									type="ACTIVE"
								/>
								<AddChildForm
									control={control}
									selectName={`policy${index}`}
									inputName={`childName${index}`}
									loading={loading}
									list={list}
								/>
							</AddChild>
						))}
					</div>
				</div>

				<FormButton licences={licences?.user?.licences} loading={loadingBtn} />
			</form>
		</FormProvider>
	)
}

export default Form
