import { FormProvider, useForm } from 'react-hook-form'
import Button from 'src/components/elements/Button'
import FieldInput from 'src/components/elements/FieldInput'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { CONFIRM_RESET_USER_PASSWORD } from 'src/graphql/mutation'
import { isNullOrUndefined, serializeErrors } from 'src/utils'
import { useNavigate, useParams } from 'react-router'
import { useDispatchUser } from 'src/context/auth'
import useMultiErrors from 'src/hooks/useMultiErrors'
import ROUTES_NAME from 'src/router/routesNames'

const validationSchema = Yup.object().shape({
	password1: Yup.string()
		.required('Field is required')
		.min(8, 'Minimum password length 8')
		.test(
			'minUppercase',
			'Password must contain at least one capital letter and digit',
			(value) =>
				isNullOrUndefined(value) ||
				(value.match(/(?=.*[0-9])(?=.*[A-Z])/g) || []).length >= 1
		),
	password2: Yup.string()
		.required('Field is required')
		.oneOf([Yup.ref('password1'), null], 'Passwords must match')
})

const PasswordReset = () => {
	const { token, uuid } = useParams()

	const methods = useForm({ resolver: yupResolver(validationSchema) })
	const {
		handleSubmit,
		formState: { errors }
	} = methods
	const { errorsPack, setErrors } = useMultiErrors(errors)

	const { dispatch } = useDispatchUser()
	const navigate = useNavigate()

	const [confirmResetPassword] = useMutation(CONFIRM_RESET_USER_PASSWORD, {
		onCompleted: (data) => {
			if (!data.confirmResetUserPassword.ok) {
				setErrors(serializeErrors(data.confirmResetUserPassword.errors))
			} else {
				dispatch({
					type: 'LOGIN',
					payload: {
						user: data.confirmResetUserPassword.user
					}
				})
				navigate(ROUTES_NAME.DASHBOARD)
			}
		}
	})

	const onSubmit = (data) => {
		confirmResetPassword({ variables: { ...data, uidb64: uuid, token } })
	}

	return (
		<div className="reset">
			<div className="container container-md-center">
				<img src="/images/logotype-big.png" alt="" className="reset__logo" />
				<h2 className="reset__title">Recover password</h2>
				<p className="reset__subtitle">
					To login to your profile, please create a new password
				</p>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FieldInput
							name="password1"
							label="New password"
							placeholder="Create new password"
							required
							isPassword
							connectForm
							className="reset__input"
							errors={errorsPack?.password1}
						/>
						<FieldInput
							name="password2"
							label="Confirm password"
							placeholder="Type new password again"
							required
							isPassword
							connectForm
							className="reset__input"
							errors={errorsPack?.password2}
						/>
						<Button type="submit" color="primary" block>
							Login with new password
						</Button>
					</form>
				</FormProvider>
				<div className="reset__copyright">© 2022 School Mobile. All rights reserved.</div>
			</div>
		</div>
	)
}

export default PasswordReset
