import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import routesNames from 'src/router/routesNames'
import styles from './RowModal.module.scss'

type ButtonProps = {
	onClick?: () => void
	children: string
	icon: string
}
const Button: React.FC<ButtonProps> = ({ onClick, children, icon }) => {
	return (
		<button className={styles.drop__btn} onClick={onClick}>
			<i className={icon} /> {children}
		</button>
	)
}
const RowModal: React.FC<{ parentId: string; open: boolean; toggle: () => void }> = ({
	open,
	toggle,
	parentId
}) => {
	const navigate = useNavigate()
	const ref = useRef()
	useOnClickOutside(ref, toggle)

	const onAddChildClick = () => {
		navigate(routesNames.DASHBOARD_ADD_CHILD, { state: { parentId } })
	}

	if (!open) {
		return null
	}
	return (
		<div className={`${styles.drop} row-modal`} ref={ref}>
			<Button icon="icon-edit">Edit parent</Button>
			<Button icon="icon-child" onClick={onAddChildClick}>
				Add child
			</Button>
			<Button icon="icon-big-delete">Remove parent</Button>
		</div>
	)
}

export default RowModal
