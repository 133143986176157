import React, { useRef, useState } from 'react'
import { useToggle } from 'react-use'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { DashboardChildType, DashboardParentType } from 'src/graphql/types'
import RowModal from '../RowModal/RowModal'

const childrenToShow = (array: Array<DashboardChildType>): number => {
	let len = 0
	let currIndex = 0
	if (!array.length) {
		return -1
	}
	// eslint-disable-next-line no-plusplus
	for (let index = 0; index < array.length; index++) {
		const element = array[index].name
		if (element.split(' ').length > 1) {
			if (len + element.length <= 48) {
				len += element.length
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				currIndex = index
			} else {
				break
			}
		} else if (len + element.length < 36) {
			len += element.length
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			currIndex = index
		} else {
			break
		}
	}
	return currIndex + 1
}

type RowProps = {
	parent: DashboardParentType
	isStatusSticky: boolean
}

const Row: React.FC<RowProps> = ({ parent, isStatusSticky }) => {
	const ref = useRef()
	const [open, toggle] = useToggle(false)
	useOnClickOutside(ref, () => toggle(false))

	const initialChildren = parent.children
	const [count, setCount] = useState(childrenToShow(initialChildren))
	const [opened, setOpened] = useState(false)
	const children = initialChildren.slice(0, count)
	const showChildrenButton = initialChildren.length
		? initialChildren.length > childrenToShow(initialChildren)
		: false

	const showAll = () => {
		if (opened) {
			setOpened(false)
			setCount(childrenToShow(initialChildren))
			return
		}
		setOpened(true)
		setCount(initialChildren.length)
	}

	return (
		<div className="table-row">
			<div className={`table-cell table-cell-name ${isStatusSticky ? '' : 'sticky-name'}`}>
				{parent.firstName} {parent.lastName}
			</div>
			<div className="table-cell">
				<a href={`emailto:${parent.email}`} className="table-email">
					{parent.email}
				</a>
			</div>
			<div className="table-cell table-cell-list">
				<div className="table-children">
					{children.map((child) => (
						<span className="table-child" key={child.id}>
							{child.name}
						</span>
					))}
					{!initialChildren.length && <p className="table-children-empty">-</p>}
				</div>
				{showChildrenButton && (
					<div className="show-more-wrapper">
						<button
							className={`icon-arrow-down-bold table-children-button ${
								opened ? 'table-children-button__active' : ''
							}`}
							aria-label="Show more"
							onClick={showAll}
						/>
					</div>
				)}
			</div>
			<div className="table-cell row-button-cell">
				<div className={`table-status status-${parent.status.toLowerCase()}`}>
					{parent.status}
				</div>
			</div>
			<div className={`setting-button ${isStatusSticky ? 'sticky-setting' : ''}`}>
				<div style={{ position: 'relative' }}>
					<button
						className="icon-more row-button"
						aria-label="Settings"
						onClick={toggle}
					/>
					<RowModal open={open} toggle={toggle} parentId={parent.id} />
				</div>
			</div>
		</div>
	)
}

export default Row
