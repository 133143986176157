import styles from 'src/components/elements/AddLicense/AddLicense.module.scss'
import { useForm, FormProvider } from 'react-hook-form'
import FormCounter from 'src/components/elements/FormCounter'
import Button from 'src/components/elements/Button'
import { useMutation } from '@apollo/client'
import { CREATE_PROGRESS_LICENCES } from 'src/graphql/mutation'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import routesNames from 'src/router/routesNames'
import { useUser } from 'src/context/auth'
import WithCredentials from 'src/hoc/withCredentials'

export type LicenceType = {
	code: string
	status: string
}

const AdminAddLicence: React.FC<{
	parentId: string
	prevStep: () => void
	nextStep: () => void
	setLicenceCodes: Dispatch<SetStateAction<LicenceType[]>>
	backButtonType: 'cancel' | 'back'
}> = ({ parentId, prevStep, nextStep, setLicenceCodes, backButtonType }) => {
	const navigate = useNavigate()
	const { state } = useUser()
	const methods = useForm({
		mode: 'onSubmit'
	})
	const { handleSubmit } = methods
	const { renderComponent, showModal, hideModal } = useGlobalBadgeContext()

	const [createLicences, { loading }] = useMutation(CREATE_PROGRESS_LICENCES, {
		onCompleted: (data) => {
			if (!data.createInProgressLicences.ok) {
				showModal('error', {
					color: 'error',
					text: 'Something went wrong.',
					time: 15000
				})
				return
			}

			setLicenceCodes(data.createInProgressLicences.licences)
			nextStep()
		}
	})

	const onBackButtonClick = () => {
		hideModal()
		prevStep()
	}

	const onCancel = () => {
		navigate(routesNames.DASHBOARD)
	}

	const onSubmit = handleSubmit((val) => {
		createLicences({ variables: { ...val, parentId } })
	})

	const backButton = useMemo(
		() => ({
			cancel: (
				<Button color="empty" onClick={onCancel}>
					Cancel
				</Button>
			),
			back: (
				<Button color="empty" classname="step-buttons__backBtn" onClick={onBackButtonClick}>
					<i className="icon-arrow-left" />
					Back
				</Button>
			)
		}),
		[backButtonType]
	)

	useEffect(() => {
		if (!state.user.isAdmin) {
			navigate(routesNames.DASHBOARD)
		}
	}, [state])

	return (
		<div className="dashboard-step__titles">
			{renderComponent()}
			<h3 className="dashboard-step__title">Safe & secure in seconds!</h3>
			<p className="dashboard-step__subtitle">Add the needed number of licences</p>
			<FormProvider {...methods}>
				<form onSubmit={onSubmit}>
					<div
						className={`${styles.extra} ${styles.blockDashboard} ${styles.extra__addParentForm}`}
					>
						<div className={`${styles.extra__info}`}>
							<div
								className={`${styles.license__paid} ${styles['paid-block-dashboard']}`}
							>
								<span
									className={`${styles.license__subtext2} ${styles['text-dashboard']}`}
								>
									annually
								</span>
								<div
									className={`${styles.license__prepaid} ${styles['prepaid-dashboard']}`}
								>
									Licence
								</div>
							</div>
							<div className={styles.license__infoBox}>
								buy now
								<br />
								pay later
							</div>
						</div>
						<div>
							<div
								className={`${styles.extra__trigger} ${styles['trigger-block-dashboard']}`}
							>
								Please choose how many children you want to protect:
							</div>
							<FormCounter name="amount" connectForm minCount={1} />
						</div>
					</div>
					<div className="step-buttons">
						<div className="step-buttons__wrapper">
							{backButton[backButtonType]}
							<Button
								type="submit"
								color="primary"
								classname="step-buttons__next"
								isLoading={loading}
							>
								Continue
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default WithCredentials(AdminAddLicence)
