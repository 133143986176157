const ROUTES_NAME = {
	HOME: '/',
	LOGIN: '/login',
	DASHBOARD: '/dashboard',
	SETTINGS: '/settings',
	STEP1: '/step1',
	STEP2: '/step2',
	STEP3: '/step3',
	PAYMENT_STEP: '/payment-step',
	RECOVER_PASSWORD: '/recover-password',
	DASHBOARD_ADD_LICENCE: '/dashboard-add-licence',
	DASHBOARD_PAYMENT: '/dashboard-payment',
	DASHBOARD_CREATE_CHILD: '/dashboard-create-child',
	DASHBOARD_CREATE_PARENT: '/dashboard-create-parent',
	DASHBOARD_GET_CONNECTED: '/dashboard-get-connected',
	DASHBOARD_ADD_CHILD: '/dashboard-add-child'
}
export default ROUTES_NAME
