import { ReactNode } from 'react'

export enum ActionEnum {
	SET_STATUS = 'SET_STATUS',
	SET_PAGE = 'SET_PAGE',
	SET_PER_PAGE = 'SET_PER_PAGE',
	SET_SORT_BY = 'SET_SORT_BY',
	SET_SEARCH = 'SET_SEARCH'
}

export type StatusPayload = 'ALL' | 'LOGGED' | 'PENDING'

export type StatusAction = {
	type: ActionEnum.SET_STATUS
	payload: StatusPayload
}

export type PageAction = {
	type: ActionEnum.SET_PAGE
	payload: number
}

export type PerPageAction = {
	type: ActionEnum.SET_PER_PAGE
	payload: number
}

export type SortByNameAction = {
	type: ActionEnum.SET_SORT_BY
	payload: {
		orderBy: 'NAME'
		sortBy: 'ASC' | 'DESC'
	}
}
export type SortByEmailAction = {
	type: ActionEnum.SET_SORT_BY
	payload: {
		orderBy: 'EMAIL'
		sortBy: 'ASC' | 'DESC'
	}
}

export type SearchAction = {
	type: ActionEnum.SET_SEARCH
	payload: string
}

export type Action =
	| PageAction
	| PerPageAction
	| SortByNameAction
	| SortByEmailAction
	| SearchAction
	| StatusAction

export type Dispatch = (action: Action) => void
export type State = {
	page: number
	perPage: number
	orderBy: 'NAME' | 'EMAIL'
	sortBy: 'ASC' | 'DESC'
	status: StatusPayload
	search: string
}
export type UserProviderProps = { children: ReactNode }
