import Button from 'src/components/elements/Button'
import emptyTableIcon from 'src/assets/images/icons/empty-table-icon.svg'
import { useNavigate } from 'react-router'
import ROUTES_NAME from 'src/router/routesNames'
import styles from './EmtyTableScreen.module.scss'

const EmptyScreen: React.FC = () => {
	const navigate = useNavigate()
	const onAddParentClick = () => {
		navigate(ROUTES_NAME.DASHBOARD_CREATE_PARENT)
	}

	return (
		<div className={styles.wrapper}>
			<img className={styles.emptyIcon} src={emptyTableIcon} alt="empty-table-icon" />
			<div className={styles.content}>
				<div className={styles.content__description}>
					<h2>Start by adding parents</h2>
					<p>
						Please add parents to the table to be aware of the current information about
						the issued licences
					</p>
				</div>
				<Button
					color="primary"
					classname="empty-table-screen__addbtn"
					onClick={onAddParentClick}
				>
					<i className="icon-plus" /> Add parents
				</Button>
			</div>
		</div>
	)
}

export default EmptyScreen
