import { useState } from 'react'
import { useDebounce, useMedia } from 'react-use'
import ROUTES_NAME from 'src/router/routesNames'
import { useNavigate } from 'react-router-dom'
import { useDispatchTable, useStateTable } from 'src/context/table'
import { StatusPayload } from 'src/context/table/types'
import { setSearch, setStatus } from 'src/context/table/actions'
import classNames from 'classnames'
import Button from 'src/components/elements/Button'
import css from './AdminTableHeader.module.scss'

type ButtonType = {
	label: string
	value: StatusPayload
}

const btns: Array<ButtonType> = [
	{
		label: 'View all',
		value: 'ALL'
	},
	{
		label: 'Pending',
		value: 'PENDING'
	},
	{
		label: 'Logged',
		value: 'LOGGED'
	}
]

const AdminTableHeader = () => {
	const { state } = useStateTable()

	const { dispatch } = useDispatchTable()
	const [search, setInputSearch] = useState<string>('')
	const navigate = useNavigate()

	useDebounce(
		() => {
			dispatch(setSearch(search))
		},
		300,
		[search]
	)

	const onClick = (value: StatusPayload) => {
		dispatch(setStatus(value))
	}

	const onAddParentClick = () => {
		navigate(ROUTES_NAME.DASHBOARD_CREATE_PARENT)
	}
	const isWide = useMedia('(min-width:1024px)')

	return (
		<div className={css.header}>
			<div className={css.header__buttons}>
				{btns.map((btn) => {
					const classname = classNames(css.header__button, {
						[css.active]: btn.value === state.status
					})
					return (
						<button
							key={btn.label}
							className={classname}
							onClick={() => onClick(btn.value)}
						>
							{btn.label}
						</button>
					)
				})}
			</div>
			<div className={css.header__search}>
				<div className={`formControl__input ${css.header__field}`}>
					<i className="icon-big-search" />
					<input
						type="text"
						className={css.header__input}
						placeholder="Search by parent, child and email"
						onChange={(e) => setInputSearch(e.target.value)}
					/>
				</div>
				<Button color="primary" classname={css.header__addBtn} onClick={onAddParentClick}>
					<i className={`icon-plus ${css.header__plusIcon}`} />
					{isWide && <span>Add parents</span>}
				</Button>
			</div>
		</div>
	)
}

export default AdminTableHeader
