import * as Yup from 'yup'

export const orderingFormData = [
	{
		label: 'First name',
		type: 'text',
		id: 'firstName',
		name: 'firstName',
		placeholder: 'Type first name'
	},
	{
		label: 'Last name',
		type: 'text',
		id: 'lastName',
		name: 'lastName',
		placeholder: 'Type last name'
	},

	{
		label: 'Email',
		type: 'email',
		id: 'email',
		name: 'email',
		placeholder: 'Type email',
		inputNativeProps: {
			autoComplete: 'email'
		}
	}
]

export const validationSchema = Yup.object().shape({
	firstName: Yup.string().required('Field is required'),
	lastName: Yup.string().required('Field is required'),
	email: Yup.string().email('Email must be a valid').required('Field is required')
})
