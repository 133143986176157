import { useDispatchTable, useStateTable } from 'src/context/table'
import {
	setSortByNameDesc,
	setSortByNameAsc,
	setSortByEmailAsc,
	setSortByEmailDesc
} from 'src/context/table/actions'

const Head = ({ isStatusSticky }) => {
	const { state } = useStateTable()
	const { dispatch } = useDispatchTable()

	const toggleOrderName = () => {
		if (state.sortBy === 'ASC') {
			dispatch(setSortByNameDesc())
		} else {
			dispatch(setSortByNameAsc())
		}
	}
	const toggleOrderEmail = () => {
		if (state.sortBy === 'ASC') {
			dispatch(setSortByEmailDesc())
		} else {
			dispatch(setSortByEmailAsc())
		}
	}

	return (
		<div className="table-head">
			<div className={`table-cell table-head-cell ${isStatusSticky ? '' : 'sticky-name'}`}>
				<button className="table-head__button" onClick={toggleOrderName}>
					Name <i className="icon-sort-solid" />
				</button>
			</div>
			<div className="table-cell table-head-cell">
				<button className="table-head__button" onClick={toggleOrderEmail}>
					Email <i className="icon-sort-solid" />
				</button>
			</div>
			<div className="table-cell table-head-cell">Children</div>
			<div className="table-cell table-head-status">Status</div>
			<div className={`mobile-empty-block ${isStatusSticky ? 'sticky-setting' : ''}`} />
		</div>
	)
}

export default Head
