import React from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import MobileMenu from 'src/components/elements/MobileMenu'
import { useUser } from 'src/context/auth'
import ROUTES_NAME from 'src/router/routesNames'
import styles from './Header.module.scss'
import { ProfileButton, Progress, LoginMenu } from './modules'

type HeaderProps = {
	type?: 'profile' | 'progress'
	step?: 1 | 2 | 3 | 4
}

const Logo: React.FC = () => {
	const { state } = useUser()
	const auth = state.user.isAuthenticated
	const link = () => {
		if (auth) {
			return ROUTES_NAME.DASHBOARD
		}
		return ROUTES_NAME.LOGIN
	}
	return (
		<Link to={link()} className={styles.header__logoLink}>
			<img src="/images/logotype-big.png" alt="" className={styles.header__image} />
		</Link>
	)
}
const Header: React.FC<HeaderProps> = ({ type, step }) => {
	const [show, toggleMenu] = useToggle(false)
	const navigate = useNavigate()
	const onLogin = () => {
		navigate(ROUTES_NAME.LOGIN)
	}
	return (
		<div className={styles.header}>
			<div className="container">
				<div className={styles.header__inner}>
					<Logo />
					{!type && <LoginMenu onLogin={onLogin} toggleMenu={toggleMenu} />}
					{/* eslint-disable-next-line */}
					{type === 'progress' ? (
						<Progress step={step} />
					) : type === 'profile' ? (
						<ProfileButton />
					) : null}
				</div>
			</div>
			{show && <MobileMenu toggleMenu={toggleMenu} />}
		</div>
	)
}

export default Header
