import CustomSelect from 'src/components/elements/CustomSelect'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { capitalizeString, serializeErrors, transformCountries } from 'src/utils'
import { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import SettingChangeBlock from 'src/components/elements/SettingChangeBlock'
import Button from 'src/components/elements/Button'
import { FormProvider, useForm } from 'react-hook-form'
import FieldInput from 'src/components/elements/FieldInput'
import { isEqual } from 'lodash'
import { UPDATE_USER } from 'src/graphql/mutation'
import { useDispatchUser } from 'src/context/auth'
import { UserType } from 'src/graphql/types'
import ConnectForm from 'src/components/elements/ConnectForm'
import { useNavigate } from 'react-router'
import useMultiErrors from 'src/hooks/useMultiErrors'
import ROUTES_NAME from 'src/router/routesNames'
import { GET_COUNTRIES } from 'src/graphql/query'
import { formData, validationSchema } from '../form'
import styles from '../Settings.module.scss'

const Form: React.FC<{ user: UserType; toggleBadge: () => void }> = ({ user, toggleBadge }) => {
	const initialValues = {
		phoneNumber: user.phoneNumber,
		lastName: capitalizeString(user.lastName),
		firstName: capitalizeString(user.firstName),
		country: {
			label: user.country.name,
			value: user.country.id,
			icon: user.country.icon
		},
		city: user.city,
		address: user.address,
		postCode: user.postCode
	}
	const { dispatch } = useDispatchUser()
	const navigate = useNavigate()
	const methods = useForm({
		mode: 'onSubmit',
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	})

	const { data: getCountries, loading: loadingCountries } = useQuery(GET_COUNTRIES)

	const countries = transformCountries(getCountries?.countries)
	const {
		handleSubmit,
		formState: { errors },
		watch
	} = methods
	const { errorsPack, setErrors } = useMultiErrors(errors)

	const allValues = watch()
	const isDisabledSubmit = useMemo(() => {
		return isEqual(allValues, initialValues)
	}, [allValues])

	const [updateUser] = useMutation(UPDATE_USER, {
		onCompleted: (data) => {
			if (data.updateUser.ok) {
				dispatch({
					type: 'LOGIN',
					payload: {
						user: data.updateUser.user
					}
				})
				setErrors({})
				window.scrollTo(0, 0)
				toggleBadge()
			} else {
				setErrors(serializeErrors(data.updateUser.errors))
			}
		}
	})

	const onSubmit = handleSubmit((val) => {
		const variables = {
			...val,
			countryId: val.country.value
		}
		updateUser({ variables })
	})
	const onCancel = () => {
		navigate(ROUTES_NAME.DASHBOARD)
	}
	return (
		<>
			<FormProvider {...methods}>
				<form className={styles.setting__form} onSubmit={onSubmit} autoComplete="off">
					{formData.map((field) => {
						if (field.id === 'country') {
							return (
								<ConnectForm name={field.id} key={field.name}>
									<CustomSelect
										isLoading={loadingCountries}
										list={countries}
										{...field}
										className="step-field-wrapper"
										defaultValue={initialValues.country}
										error={errorsPack}
										withSearch
									/>
								</ConnectForm>
							)
						}

						return (
							<FieldInput
								key={field.name}
								{...field}
								connectForm
								className="step-field-wrapper"
								errors={errorsPack[field.name]}
								required
							/>
						)
					})}
					<div className="step-buttons">
						<div className="step-buttons__wrapper">
							<Button color="empty" onClick={onCancel}>
								Cancel
							</Button>
							<Button type="submit" color="primary" isDisable={isDisabledSubmit}>
								Save changes
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
			<SettingChangeBlock label="Email" text="email" email={user.email} />
			<SettingChangeBlock label="Password" text="password" />
		</>
	)
}

export default Form
