import React, { useState } from 'react'
import { DashboardType } from 'src/graphql/types'
import emptyTableIcon from 'src/assets/images/icons/no-matches.svg'
import { Head, Row, Footer } from './modules'

type TableProps = {
	parents: DashboardType
}
const Table: React.FC<TableProps> = ({ parents }) => {
	const [isLeftSide, setIsLeft] = useState(true)
	const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const { clientWidth, scrollLeft, scrollWidth } = e.currentTarget
		if (scrollLeft === 0) {
			setIsLeft(true)
		} else if (scrollLeft === scrollWidth - clientWidth) {
			setIsLeft(false)
		}
	}
	return (
		<div className="table">
			<div className={`table-empty ${parents?.parents?.length === 0 ? 'empty-show' : ''}`}>
				<img className="" src={emptyTableIcon} alt="empty-table-icon" />
				<h2>There is nothing that matches your request</h2>
				<p>Please, select the other options again to see the data table you need.</p>
			</div>

			{parents?.parents?.length ? (
				<>
					<div className="table-wrapper" id="table-wrapper" onScroll={onScroll}>
						<Head isStatusSticky={isLeftSide} />
						<div className="table-body">
							{parents?.parents?.map((parent) => (
								<Row parent={parent} key={parent.id} isStatusSticky={isLeftSide} />
							))}
						</div>
					</div>
					<Footer totalCount={parents?.quantity ?? 0} />
				</>
			) : null}
		</div>
	)
}

export default Table
