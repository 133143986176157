import { FormProvider, useForm } from 'react-hook-form'
import { AddChild, AddChildForm, AddChildHeader } from 'src/components/modules/AddChild'
import { useGlobalBadgeContext } from 'src/context/global-badge'
import { LicenceType } from 'src/components/modules/AdminAddLicence/AdminAddLicence'
import { useMutation, useQuery } from '@apollo/client'
import { GET_POLICIES } from 'src/graphql/query'
import { capitalizeString, serializeErrorsOnSteps, transformPolicies } from 'src/utils'
import { FormButton } from 'src/pages/Step2/Form'
import { CREATE_CHILD_MANUALLY } from 'src/graphql/mutation'
import { useNavigate } from 'react-router-dom'
import WithCredentials from 'src/hoc/withCredentials'
import routesNames from 'src/router/routesNames'
import { useUser } from 'src/context/auth'
import { useEffect } from 'react'

const AdminAddChild: React.FC<{
	licences: LicenceType[]
	parentId: string
	prevStep: () => void
}> = ({ licences, parentId, prevStep }) => {
	const navigate = useNavigate()
	const { state } = useUser()
	const methods = useForm({
		mode: 'onSubmit'
	})
	const { renderComponent, showModal, hideModal } = useGlobalBadgeContext()

	const { handleSubmit, control } = methods
	const { data: policies, loading } = useQuery(GET_POLICIES)
	const list = transformPolicies(policies?.policies)

	const [createChild, { loading: loadingBtn }] = useMutation(CREATE_CHILD_MANUALLY, {
		onCompleted: (data) => {
			if (data.createChildManually.ok) {
				navigate(routesNames.DASHBOARD_GET_CONNECTED, { state: { parentId } })
				return
			}

			if (data.createChildManually.errors) {
				window.scrollTo(0, 0)
				const error = serializeErrorsOnSteps(data.createChildManually.errors)
				showModal('error', {
					color: 'error',
					text: error,
					time: 10000
				})
			}
		}
	})

	const onBackButtonClick = () => {
		hideModal()
		prevStep()
	}

	const onSubmit = handleSubmit((data) => {
		const children = licences.map((item, index) => {
			return {
				name: capitalizeString(data[`childName${index}`]),
				policyId: data[`policy${index}`].value,
				licenceCode: item.code
			}
		})

		createChild({ variables: { children, parentId } })
	})

	useEffect(() => {
		if (!state.user.isAdmin) {
			navigate(routesNames.DASHBOARD)
		}
	}, [state])

	return (
		<FormProvider {...methods}>
			<form onSubmit={onSubmit} className="step__form step__form__add-parent step__pt_0">
				<div>
					{renderComponent()}
					<div className="step__titles">
						<h2 className="step__title">Create a profile for the child</h2>
						<p className="step__subtitle">
							Configure the child's profile by adding a <strong>name</strong> and{' '}
							<strong>policy</strong>
						</p>
					</div>
					<div className="step__desktopGrid">
						{licences.map((license, index) => (
							<AddChild key={license.code}>
								<AddChildHeader
									index={index}
									status={license.status}
									type="ACTIVE"
								/>
								<AddChildForm
									control={control}
									selectName={`policy${index}`}
									inputName={`childName${index}`}
									loading={loading}
									list={list}
								/>
							</AddChild>
						))}
					</div>
				</div>

				<FormButton
					licences={licences}
					loading={loadingBtn}
					isBackButton
					onBackClick={onBackButtonClick}
				/>
			</form>
		</FormProvider>
	)
}

export default WithCredentials(AdminAddChild)
